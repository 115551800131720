import * as React from "react";
import styled from "styled-components";

type DropdownMenuProps = {} & React.SelectHTMLAttributes<HTMLSelectElement>;

// TODO: bottom-underline option?
const StyledDropdownMenu = styled.select`
  padding-top: ${(props) => props.theme.spacings.dropdowns.paddingVertical}px;
  padding-bottom: ${(props) =>
    props.theme.spacings.dropdowns.paddingVertical}px;
  padding-left: ${(props) =>
    props.theme.spacings.dropdowns.paddingHorizontal}px;
  padding-right: ${(props) =>
    props.theme.spacings.dropdowns.paddingHorizontal + 8}px;

  /* Remove ugly Safari styling, and replace with custom down arrow */
  appearance: none;
  background-image: ${(props) => {
    const col = props.theme.colors.gui.controls.foreground;
    return `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='${col}'><polygon points='0,0 100,0 50,50'/></svg>")`;
  }};
  background-size: 8px;
  background-position: right 6px top 60%;
  background-repeat: no-repeat;
  background-color: ${(props) => props.theme.colors.gui.controls.background};

  color: ${(props) => props.theme.colors.gui.controls.foreground};
  border: 0;
  border-radius: 0;
  :hover {
    background-color: ${(props) =>
      props.theme.colors.gui.controls.background.lighter};
  }
  /* transition: background-color ${(props) =>
    props.theme.timings.guiFadeSeconds}s
    ease; */

  font-size: 1em;
`;

const DropdownMenuInner = (
  { ...props }: DropdownMenuProps,
  ref: React.Ref<HTMLSelectElement>
) => {
  return <StyledDropdownMenu {...props} ref={ref} />;
};

export const DropdownMenu = React.forwardRef(DropdownMenuInner);
