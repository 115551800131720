import * as React from "react";
import styled from "styled-components";

type ToolbarProps = {
  align?: "start" | "center";
  children: React.ReactNode;
};

export const ToolbarSeparator = styled.hr``;

export const ToolbarSpacer = styled.li`
  flex-grow: 1;
  margin-right: -8px; // Hack to prevent jittering on narrow toolbar wrap
`;

const StyledToolbar = styled.menu<{ $align: ToolbarProps["align"] }>`
  background-color: ${(props) => props.theme.colors.gui.toolbarBackground};

  display: flex;
  flex-wrap: wrap;
  gap: 2px ${(props) => props.theme.spacings.panels.gap}px;
  list-style: none;
  margin: 0;
  align-items: stretch;
  justify-content: ${(props) => props.$align ?? "start"};

  font-family: ${(props) => props.theme.fonts.family.gui};

  * {
    font-family: ${(props) => props.theme.fonts.family.gui};
  }

  > li {
    display: flex;
    align-items: center;
  }
`;

export const Toolbar = (props: ToolbarProps) => {
  return (
    <StyledToolbar $align={props.align}>
      {React.Children.map(props.children, (child) => {
        switch (!!child && (child.type ?? false)) {
          case false:
            return null;
          case ToolbarSpacer:
            return child;
          default:
            return <li>{child}</li>;
        }
      })}
    </StyledToolbar>
  );
};
