import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import styled from "styled-components";
import { PostContent } from "../gui/atoms/post-content";

const RContent = styled.div<{ $useGrayscaleMode: boolean }>`
  font-family: ${(props) => props.theme.fonts.family.body};
  font-size: 0.875rem;
  color: black;
  li {
    margin-left: 0.3in;
  }

  /* Override RLink */
  &&& a {
    ${(props) => props.$useGrayscaleMode && `color: black;`}
    :hover, :focus {
      ${(props) =>
        props.$useGrayscaleMode && `color: #999;`}// TODO: black.lighter_2x?
    }
  }
  @media screen {
    &&& a {
      ${(props) =>
        props.$useGrayscaleMode && `text-decoration: underline dotted;`}
    }
  }
`;

const RLink = styled(OutboundLink).attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: ${(props) => props.theme.colors.palette.purple};
  text-decoration: dotted;
  transition: color 0.15s ease;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.palette.purple.lighter};
  }
`;

const RHorizontalList = styled.ul`
  li {
    list-style: none;
    display: inline;
    margin: 0;
    :not(:first-child):before {
      content: " · ";
    }
  }
`;

const RNoteParagraph = styled.span`
  font-size: smaller;
  font-style: italic;
`;

const RTitle = styled.h1`
  font-size: 3em;
`;

const RSubtitle = styled(RHorizontalList)`
  > * {
    :not(:first-child)::before {
      content: "·";
      padding: 0 0.5em;
    }
  }
  > * {
    display: inline;
  }
  ${RLink} {
    display: inline-block;
  }
`;

const RH2 = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  margin: 0.175in 0 0;
`;

const RH3 = styled.h3`
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 0.125in;
  display: flex;
  align-items: baseline;
  > :last-child {
    margin-left: auto;
    font-weight: 400;
    font-size: 0.9em;
    flex-shrink: 0;
  }
`;

const RH4 = styled.h4`
  margin-top: 0.1in;
  display: flex;
  font-size: 1em;
  & > span > span {
    font-size: 1em;
    font-weight: 400;
    ::before {
      font-size: 1.15em;
      content: "|";
      padding: 0 0.5em;
    }
  }
  & > :last-child {
    margin-left: auto;
    font-weight: 400;
  }
`;

const RSplitEducationWrapper = styled.div`
  display: flex;
  column-gap: 2em;
  flex-wrap: wrap;
  ${RH2} + & {
    margin-top: -2mm;
  }
`;

const RInternshipRowGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 1em;
  align-items: start;
`;

const RFooter = styled.div`
  text-align: center;
  margin-top: 0.6in;
  color: #999;
`;

export default function ResumeContent(props: { useGrayscaleMode: boolean }) {
  return (
    <RContent $useGrayscaleMode={props.useGrayscaleMode}>
      <RTitle>Simon Tang</RTitle>
      <RSubtitle>
        <span>Software Developer with a focus on Web, UX, and Security</span>{" "}
        <RLink href="https://www.simontang.dev/resume">
          simontang.dev/resume
        </RLink>{" "}
        <RLink href="https://www.linkedin.com/in/Simon--Tang">
          linkedin.com/in/Simon--Tang
        </RLink>
      </RSubtitle>

      <RH2>Work Experience</RH2>
      <RSectionConnected />
      <RSectionRangle />

      <RH2>Education</RH2>
      <RSplitEducationWrapper>
        <div>
          <RH4 as="h3">University of Waterloo</RH4>
          <p>
            Bachelor of Computer Science
            {/* <br />  */} <span style={{ padding: "0 0.25em" }}>/</span>{" "}
            Human-Computer Interaction Option
            {/* <br />  */} <span style={{ padding: "0 0.25em" }}>/</span>{" "}
            Cooperative Education
          </p>
        </div>
        {/* <div style={{ flexGrow: 1 }}>
          <RH4 as="h3">Internships</RH4>
          <RSectionInternships />
        </div> */}
      </RSplitEducationWrapper>

      <RH2>Computer Security</RH2>
      <RSectionComputerSecurity />

      {/* <RFooter>Last updated: 2022-11</RFooter> */}
    </RContent>
  );
}

const RSectionConnected = () => (
  <>
    <RH3>
      <span>
        {/* <RLink href="https://connected.io">Connected</RLink>{" "} */}
        <span style={{ fontWeight: 500 }}>Lead Developer at</span>{" "}
        <RLink href="https://web.archive.org/web/20221218090056/https://connected.io/">
          Connected
        </RLink>{" "}
        <RNoteParagraph as="span" style={{ fontWeight: 400 }}>
          <small>
            (now{" "}
            <RLink href="https://www.thoughtworks.com/en-ca">
              Thoughtworks Canada
            </RLink>
            )
          </small>
        </RNoteParagraph>
      </span>
      <span>2019 - Present</span>
    </RH3>
    <RNoteParagraph>
      Connected was a cross-disciplinary firm that specialized in end-to-end
      product discovery and delivery for Fortune 500 clients.{" "}
    </RNoteParagraph>
    <RH4>Client 4: Fitness Technology Company (2023 - Present)</RH4>
    <ul>
      <li>
        Currently prototyping a digital fitness experience that integrates
        exercise equipment with gaming and immersive social experiences{" "}
        <strong>(Unity, C#, F#)</strong>
      </li>
      <li>
        Introduced custom binary serializers to compress large Unity assets,
        achieving a 90% reduction in selected files
      </li>
      <li>
        Authored architectural documents (ADRs) to plan major changes and
        document complex systems for stakeholders
      </li>
    </ul>
    <RH4>Client 2: Social Technology Company (2021 - 2023)</RH4>
    <ul>
      <li>
        Optimized a team-building chatbot and web app for mobile devices by
        introducing responsive design, resulting in a sevenfold increase in ad
        conversion rates; featured on Product Hunt in 2022{" "}
        <strong>(React, GraphQL, StyleX, Hack/PHP)</strong>
      </li>
      <li>
        Updated company-wide code libraries for processing cookie consent,
        coordinating across multiple internal teams to resolve urgent GDPR
        concerns; built a versioned 3D asset management system for a prototype
        VR app; developed Firebase integrations to load user-generated media
        into automated content safety systems
      </li>
    </ul>
    <RH4>Client 1: Automotive Company (2019 - 2021)</RH4>
    <ul>
      <li>
        Built a global predictive analytics platform using{" "}
        <strong>Apache Spark</strong> and <strong>Spring</strong> that captures
        billions of daily data points, and produces daily vehicle prognostics
        for millions of drivers; launched in six countries across NA and EU{" "}
        <strong>(Java, Python, Hive, SQL Server, Jupyter)</strong>
      </li>
      <li>
        Developed cross-functional initiatives to address technical debt;
        reduced daily compute time by 75% by optimizing data processing
        algorithms; built custom tooling to eliminate weeks of manual validation
        processes
      </li>
    </ul>
  </>
);

const RSectionRangle = () => (
  <>
    <RH3>
      <span>
        <RLink href="https://rangle.io">Rangle.io</RLink>
      </span>
      <span>2018 - 2019</span>
    </RH3>
    <ul>
      <li>
        Built Rangle's new JAMstack-powered website, with a strong emphasis on
        fully accessible design (WCAG 2.0 AA), responsive (mobile-friendly)
        design, codeless layout control, and CMS integration{" "}
        <strong>(React, GatsbyJS, Contentful)</strong>
      </li>
      <li>
        Launched a secure online portal for healthcare providers to monitor and
        visualize blood glucose levels for patients; optimized data
        visualizations by applying performance benchmarking in the web browser{" "}
        <strong>(React, TypeScript)</strong>
      </li>
    </ul>
  </>
);

const RSectionInternships = () => (
  <RInternshipRowGrid>
    <RLink href="https://www.tulip.com">Tulip</RLink>
    <RHorizontalList>
      <li>JavaScript (React, Redux)</li>
      <li>PHP</li>
    </RHorizontalList>
    <span>2017</span>

    <RLink href="https://www.opentext.com">OpenText</RLink>
    <RHorizontalList>
      <li>JavaScript (React, NodeJS, Jest)</li>
    </RHorizontalList>
    <span>2017</span>

    <RLink href="https://www.renre.com/reinsuranceinsurance/">
      Validus Research
    </RLink>
    <RHorizontalList>
      <li>Python (Flask)</li>
      <li>SQL Server</li>
      <li>C#</li>
      <li>D3.js</li>
    </RHorizontalList>
    <span>2016</span>

    <RLink href="https://n8id.com/">N8 Identity</RLink>
    <RHorizontalList>
      <li>Java (Spring)</li>
      <li>PostgreSQL</li>
    </RHorizontalList>
    <span>2015</span>
  </RInternshipRowGrid>
);
const RSectionComputerSecurity = () => (
  <>
    <ul>
      <li>
        {/* (2022){" "} */}
        <RLink href="https://nvd.nist.gov/vuln/detail/CVE-2022-22656">
          macOS
        </RLink>{" "}
        - A local vulnerability to bypass the macOS fast user switching screen.{" "}
        <RNoteParagraph as="span">
          {/* <RLink href="https://nvd.nist.gov/vuln/detail/CVE-2022-22656">
            CVE-2022-22656
          </RLink> */}
          <RLink href="https://support.apple.com/en-ca/HT213488#:~:text=like%20to%20acknowledge-,Simon%20Tang,-(simontang.dev)%20for">
            (credits)
          </RLink>
          {/* ; incorrectly reported as fixed in{" "}
          <RLink href="https://support.apple.com/en-ca/HT213183#:~:text=improved%20state%20management.-,CVE%2D2022%2D22656,-MobileAccessoryUpdater">
            12.3
          </RLink>
          /
          <RLink href="https://support.apple.com/en-ca/HT213184#:~:text=improved%20state%20management.-,CVE%2D2022%2D22656,-MobileAccessoryUpdater">
            11.6.5
          </RLink>
          /
          <RLink href="https://support.apple.com/en-ca/HT213185#:~:text=improved%20state%20management.-,CVE%2D2022%2D22656,-MobileAccessoryUpdater">
            2022-003 Catalina
          </RLink> */}
        </RNoteParagraph>
      </li>
      <li>
        {/* (2021)  */}
        {/* HackerOne #1526766 */}
        {/* <RLink href="https://hackerone.com/salesforce_vip">Salesforce</RLink> - */}
        <RLink href="https://help.salesforce.com/s/articleView?id=000393408&type=1">
          Salesforce
        </RLink>{" "}
        - An XSS vulnerability to execute arbitrary JavaScript code across
        affected web domains.{" "}
        <RNoteParagraph as="span">
          <RLink href="https://security.salesforce.com/security-research-contributors">
            (credits)
          </RLink>
        </RNoteParagraph>
      </li>
    </ul>
  </>
);

const StyledResumeAboutWindowContent = styled(PostContent)`
  font-size: large;
  width: min(500px, 80vw);
  max-height: max(120px, calc(100vh - 280px));
  overflow: auto;
`;

export function ResumeAboutWindowContent() {
  return (
    <StyledResumeAboutWindowContent>
      <p>
        The best way to contact me is via a{" "}
        <RLink href="https://www.linkedin.com/in/Simon--Tang">LinkedIn</RLink>{" "}
        direct message.
      </p>
    </StyledResumeAboutWindowContent>
  );
}
