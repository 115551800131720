import { faSmile } from "@fortawesome/free-regular-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ResumeColorMode,
  ResumeLayoutMode,
  resumeConfigSelector,
  setResumeColorMode,
  setResumeLayoutMode,
  setShowModalAboutMe,
} from "../../store/resume/resume-slice";
import { Button, LinePosition } from "../gui/atoms/button";
import { DropdownMenu } from "../gui/atoms/dropdown-menu";
import { Label } from "../gui/atoms/label";
import { Toolbar, ToolbarSpacer } from "../gui/molecules/toolbar";

const printResumePopup = () => {
  const w = window.open(
    "/print/resume/",
    "_blank",
    "popup=true,width=1200,height=900,left=120,top=120"
  );
  if (w === null) {
    window.alert("Could not open a pop-up window for printing/saving as PDF.");
    return;
  }
};

export const ResumeToolbar = (props: {
  enableShowModalAboutMe?: boolean;
  hideLayoutModeSelector?: boolean;
  printInPopup?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { resumeColorMode, resumeLayoutMode, showModalAboutMe } =
    useAppSelector(resumeConfigSelector);
  const {
    enableShowModalAboutMe = false,
    hideLayoutModeSelector = false,
    printInPopup = false,
  } = props;
  return (
    <Toolbar>
      {!hideLayoutModeSelector && (
        <Label text="Layout">
          <DropdownMenu
            onChange={(e) =>
              dispatch(setResumeLayoutMode(e.target.value as ResumeLayoutMode))
            }
            value={resumeLayoutMode}
          >
            <option value={ResumeLayoutMode.AUTO}>Auto</option>
            <option value={ResumeLayoutMode.PRINT}>Print</option>
            <option value={ResumeLayoutMode.WEB}>Web</option>
          </DropdownMenu>
        </Label>
      )}
      <Label text="Colours">
        <DropdownMenu
          onChange={(e) =>
            dispatch(setResumeColorMode(e.target.value as ResumeColorMode))
          }
          value={resumeColorMode}
        >
          <option value={ResumeColorMode.COLORFUL}>Colourful</option>
          <option value={ResumeColorMode.GRAYSCALE}>Greyscale</option>
        </DropdownMenu>
      </Label>
      <ToolbarSpacer />

      {enableShowModalAboutMe && (
        <Button
          onClick={() => {
            dispatch(setShowModalAboutMe(!showModalAboutMe));
          }}
          faIcon={{ icon: faSmile }}
          linePosition={LinePosition.BOTTOM}
        >
          Contact Me
        </Button>
      )}
      <Button
        onClick={() => {
          if (printInPopup) {
            printResumePopup();
          } else {
            window.print();
          }
        }}
        faIcon={{ icon: faPrint }}
        linePosition={LinePosition.BOTTOM}
      >
        Print
      </Button>
    </Toolbar>
  );
};
