import * as React from "react";
import type { HeadFC } from "gatsby";
import ResumeDoc from "../../components/resume/resume-doc";
import { ResumeToolbar } from "../../components/resume/resume-toolbar";
import styled from "styled-components";
import { useAppSelector } from "../../app/hooks";
import {
  ResumeColorMode,
  resumeConfigSelector,
} from "../../store/resume/resume-slice";

const StyledToolbarWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export default function PrintableResumePage() {
  const { resumeColorMode } = useAppSelector(resumeConfigSelector);
  const useGrayscaleMode = resumeColorMode === ResumeColorMode.GRAYSCALE;
  return (
    <>
      <StyledToolbarWrapper>
        <ResumeToolbar hideLayoutModeSelector={true} />
      </StyledToolbarWrapper>
      <ResumeDoc usePrintLayout={true} useGrayscaleMode={useGrayscaleMode} />
    </>
  );
}

export const Head: HeadFC = () => <title>Resume | simontang.dev</title>;
