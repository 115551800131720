import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import ResumeContent from "./resume-content";

const RPage = styled.div<{ usePrintLayout: boolean }>`
  margin: 0;
  overflow: hidden;
  box-sizing: border-box;
  page-break-after: always;
  padding: 0.6in 0.6in 0;
  background-color: white;

  @media screen {
    ${(props) =>
      props.usePrintLayout
        ? `
        width: 216mm;
        height: 279mm;
        box-shadow: ${props.theme.shadows.soft.light};
        // TODO: get rid of the margins on this?
        margin: 20mm auto 20mm;
        `
        : `
        max-width: fit-content;
        padding: 0.4in;
        `}
  }
`;

const GlobalResumePrintStyles = createGlobalStyle`
  @page { margin: 0 }
  @media print {
    body {
      background-color: white !important;
    }
  }
`;

type ResumeDocProps = {
  usePrintLayout: boolean;
  useGrayscaleMode: boolean;
};

export default function ResumeDoc(props: ResumeDocProps) {
  return (
    <RPage usePrintLayout={props.usePrintLayout}>
      <GlobalResumePrintStyles />
      <ResumeContent useGrayscaleMode={props.useGrayscaleMode} />
    </RPage>
  );
}
