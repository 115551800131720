import * as React from "react";
import styled from "styled-components";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  children: React.ReactElement;
  text: string;
}

const StyledLabel = styled.label`
  display: flex;
  gap: ${(props) => props.theme.spacings.labels.gap}px;
  align-items: stretch;
  background-color: ${(props) => props.theme.colors.gui.controls.background};
  color: ${(props) => props.theme.colors.gui.controls.foreground};
`;

const StyledLabelText = styled.span`
  padding: ${(props) => props.theme.spacings.labels.gap}px;
  padding-right: 0;
  opacity: 0.8;
`;

export const Label = ({ children, text, ...props }: LabelProps) => {
  return (
    <StyledLabel {...props}>
      <StyledLabelText>{text}</StyledLabelText>
      {children}
    </StyledLabel>
  );
};
